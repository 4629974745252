@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

body {
  /* font-family: monospace; */
}

/** COMMON */

.main-border {
  border: 1px solid lightgrey;
  border-radius: 5px;
}

.is-monospace {
  font-family: monospace;
}

/** OTHER INPUtS **/

input.custom-input {
  font-family: monospace;
  border: 0;
  padding: 0;
  background-color: lightgreen;
}

input.custom-input::placeholder {
  background-color: rgb(213, 140, 213);
  color: white;
}

input.custom-input:focus {
  border: 1px solid purple;
  outline: 0;
  box-sizing: content-box;
}

label.custom-label {
  font-family: monospace;
  font-size: medium;
}

select.custom-select {
  background-color: lightgreen;
  font-family: monospace;
  border: 0;
}

select.custom-select:focus {
  border: 1px solid purple;
  outline: 0;
  box-sizing: content-box;
}

button.custom-button {
  background: none;
  font-family: monospace;
  color: #c6c6c6;
  border: 0;
  padding: 0;
}

/** TEXTAREA */

textarea.custom-textarea {
  background: transparent;
  border-radius: 0;
  border: none;
  font-size: 14px;
  line-height: 1.5;
  padding: 0px 0;
  resize: none;
  transition: border-color 150ms ease;
  width: 100%;
  scroll-behavior: none;
  margin-bottom: 0px !important;
  font-family: monospace;
}

textarea.custom-textarea:disabled {
  color: #c6c6c6;
}

textarea.custom-textarea:hover {
  border-color: #c6c6c6;
  color: blue;
}

textarea.custom-textarea:focus {
  outline: none;
  border-color: #c6c6c6;
}

textarea.custom-textarea::placeholder {
  color: #c6c6c6;
}

.is-white {
  background-color: transparent !important;
}

/** RESULTS */

.plus-button {
  width: 26px;
  height: 26px;
  font-size: xx-small !important;
}

.top-content {
  border-top: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  border-bottom: 1px dashed lightgrey;
  border-radius: 5px 5px 0px 0px;
}

.normal-content {
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  margin-top: -13px;
  border-bottom: 1px dashed lightgrey;
}

.bottom-content {
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  margin-top: -13px;
  border-bottom: 1px solid lightgrey;
  border-radius: 0px 0px 5px 5px;
}

.button-holder {
  margin-top: -13px;
  background-color: transparent !important;
}

.border-middle {
  border-bottom: 1px dashed lightgrey;
}